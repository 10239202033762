<template>
  <div>
    <label>{{ $t('creator_mobile') }} / {{ $t('creator_name') }} <span v-if='isEdit' class='text-success'>(تعديل)</span></label>

    <v-select
      v-model='customer_id'
      :dir='$store.state.appConfig.layout.direction'
      label='name'
      :options='customers'
      :reduce='item => item.id'
      @search='onSearch'
      :placeholder="$t('select')"
    >
      <template #selected-item='{ option }'>
        <div style='direction: ltr; text-align: right;display: flex;justify-content: end'>
          <p v-if='option.name'>{{ option.name }}</p>
        </div>
      </template>
      <template #option='option'>
        <div style='direction: ltr; text-align: right;display: flex;justify-content: end'>
          <p v-if='option.name'>{{ option.name }}</p>
        </div>
      </template>
      <template #no-options>{{ $t('No options') }}</template>
    </v-select>

  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  name: 'CustomerSelector',
  components: {
    ValidationProvider,
    'v-select': vSelect,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      customer_id: null,
      customers: [], // This will hold the options for the select
      searchQuery: '', // This will hold the search query
    }
  },
  watch: {
    searchQuery(newQuery) {
      if (newQuery) {
        this.onSearch(newQuery)
      }
    },
    'customer_id': {
      handler(val) {
        this.$emit('update:customer_id', val)
      },
    },
  },

  created() {
    this.getCustomers()
  },
  methods: {
    onSearch: _.debounce(function(query) {
      this.getCustomers(query)
    }, 1200),

    getCustomers(query = null) {
      this.axios.get('customers', {
        params: {
          per_page: 25,
          name: query,
          is_list: true,
          customer_id: this.customer_id,
        },
      })
        .then(res => {
          this.customers = res.data.data
        })
    },
  },
}
</script>

<style lang='scss' scoped>

</style>
